// import { Loading } from 'element-ui';
import { checkUrl } from '@/utils/utils'
import axios from 'axios';
import { Message } from 'element-ui';
window.$glob = {
  url: '',
  params: {},
  query: {},
  header: {}
};
function getGlobParams () {
  var query = window.location.search.substring(1);
  query = query.split("&");
  query.forEach(ele => {
    var pair = ele.split("=");
	window.$glob.params[pair[0]] = pair[1]
  })
}
/**
 *  显示消息
 * @param {Object} rate
 */
function showMessage(msg,type=1) {
	const message = window.document.createElement('div');
	message.style.position = 'fixed';
	message.style.top = '100px';
	message.style.right = '42%';
	message.style.width = "16%";
	message.style.fontSize = "14px";
	message.style.color = "red";
	message.style.background = "rgb(247 207 207)"
	message.style.borderRadius = '6px';
	message.style.padding = '12px';
	message.style.zIndex = 9999
	
	message.textContent = `${msg}`;
 
	window.document.body.appendChild(message);
 
	// 1秒后移除消息提示
	setTimeout(() => {
		if(type==1){
			// window.location.href = "https://stat.if1f.com/admin"
			window.location.href = "https://data.stat.if1f.com/admin"
		}else if(type==2){
			// window.location.href = "https://stat.if1f.com/member"
			window.location.href = "https://data.stat.if1f.com/total"
		}else{
			// window.location.href = "https://stat.if1f.com/mch"
			window.location.href = "https://data.stat.if1f.com/mch"
		}
		
	}, 200);
}
getGlobParams();
axios.defaults.timeout = 30000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
// let loadingInstance = '';
// axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  // loadingInstance = Loading.service({
  //   text: '拼命加载中',
  //   background: 'rgba(0,0,0,0)',
  //   spinner: 'el-icon-loading'
  // });
  if (!checkUrl(config.url)) config.url = window.$glob.url + config.url;
  let header = window.$glob.header || {};
  config.headers = Object.assign(config.headers, header);
  let data = window.$glob.query || {}
  let key;
  if (['get', 'delete'].includes(config.method)) {
    key = "params"
  } else if (['post', 'put'].includes(config.method)) {
    key = "data"
  }
  if (typeof (config[key]) === 'object') {
    config[key] = Object.assign(config[key] || {}, data)
  }
  if (config.headers.proxy) {
    let headers = {}
    for (let ele in config.headers) {
      if (typeof (config.headers[ele]) !== 'object') headers[ele] = config.headers[ele]
    }
    let form = {
      url: config.url,
      method: config.method,
      headers: headers
    }
    form[key] = config[key]
    config.url = window.$website.url + '/visual/proxy'
    config.method = 'post';
    config.data = form
  }
  return config
}, error => {
  return Promise.reject(error)
});
//HTTPrequest拦截
axios.interceptors.response.use(config => {
  // loadingInstance.close();
  const status = config.status
 // if(config.data.status==404){
 //  	  showMessage('您还未登录!',config.data.type)
 //  	  return
 //  }
  if (status != 200) {
    Message({
      message: config.data.msg,
      type: 'error'
    });
    return Promise.reject(new Error(config.data.msg));
  }
  return config;
}, error => {
  // loadingInstance.close();

  return Promise.reject(new Error(error));
})

export default axios;
