import Vue from 'vue'
import ElementUI from 'element-ui';
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'
import router from './router.js';
import { website } from '@/config.js'
import draggable from '@/page/components/draggable'
import { loadScript } from '@/utils/utils'
import { Message } from 'element-ui';
import App from './App.vue'
import './styles/common.scss'
import '@/utils/es6'
import hljs from 'highlight.js' //导入代码高亮文件
import 'highlight.js/styles/atom-one-dark.css'  //导入代码高亮样式
// import '@/mock/'
//导入主题文件
import '@/theme/index.js'
Vue.config.productionTip = false
window.axios = axios;
window.$loadScript = loadScript;
document.title = website.title

Vue.use(ElementUI);
Vue.use(window.AVUE);
Vue.component('avue-draggable', draggable)
Vue.prototype.$domain = 'https://data.stat.if1f.com/api/'
Vue.prototype.$website = website;
Vue.prototype.$message = Message
Vue.use(dataV)
Vue.directive('highlight', function (el) {
  let highlight = el.querySelectorAll('pre code');
  highlight.forEach((block) => {
    hljs.highlightBlock(block)
  })
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
